
import React from 'react';


const Footer = () => {
  return (
    <footer className="footer">
      <p className="footer-text">© 2024. Wszelkie prawa zastrzeżone.</p>
    </footer>
  );
}

export default Footer;
