import React from "react";
import Header from "./Header";
import Footer from "./Footer";

const About = () => {
  return (
    <>
      <Header />
      <div className="aboutus_content">
      <h1>O nas</h1>
        <p><br/>
          Witamy w Liczniku znaków, miejscu, w którym znajdziesz dokładny i
          łatwy w użyciu licznik znaków online. Jesteśmy zespołem pasjonatów
          twórców stron internetowych, zajmujących się tworzeniem prostych, ale
          potężnych narzędzi, które pomogą Ci usprawnić procesy pisania i
          edytowania. <br/><br/>Naszym celem jest zapewnienie płynnego liczenia znaków,
          słów i spacji, zapewniając spełnienie limitów znaków w mediach
          społecznościowych, esejach i profesjonalnych zadaniach pisarskich.
          Wierzymy w prostotę, precyzję i skuteczność – wartości, które
          przyświecają nam w każdym projekcie, którego się podejmujemy.<br/><br/>
          Niezależnie od tego, czy jesteś pisarzem, studentem czy
          profesjonalistą, nasze narzędzie zostało zaprojektowane tak, aby pomóc
          Ci skoncentrować się na treściach, nie martwiąc się o szczegóły
          techniczne.<br/><br/> Dziękujemy, że wybrałeś nas jako zaufanego partnera w
          zakresie liczenia znaków! <br/><br/> Zachęcamy do skontaktowania się z nami w
          celu przekazania wszelkich opinii lub sugestii.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default About;
