import { Link } from "react-router-dom";
import React, { useState } from 'react';  // Import useState
import Logo from "../images/logo.png"

const Header = () => {
  const [menu, setMenu] = useState(false); // Use useState to handle menu state

  const handleMenu = () => {
    setMenu(!menu);
    document.body.style.overflow = menu ? 'auto' : 'hidden';  // Prevent scroll when menu is open
  };

  return (
    <header className="header">
      {/* Left: Logo */}
      <div className="logo">
        <img src={Logo} alt="Logo" className="logo-image" />
      </div>
      
      {/* Right: Navigation */}
      <nav className="nav">
        {/* Menu button for mobile */}
        <div className="left_menu" onClick={handleMenu}>
          <i className="fa-solid fa-bars"></i> {/* Use 'className' instead of 'class' in JSX */}
        </div>

        {/* Links */}
        <ul className={`menu ${menu ? "open" : ""}`}>
          <li className="nav-li">
            <Link to="/" className="nav-link">Licznik Znaków</Link>
          </li>
          <li className="nav-li">
            <Link to="/about" className="nav-link">O nas</Link>
          </li>
          <li className="nav-li">
            <Link to="/contact" className="nav-link">Kontakt</Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
