import React, { useState } from "react";
import Header from "./Header";
import Footer from "./Footer";

const WordCounter = () => {
  const [text, setText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [charCount, setCharCount] = useState(0);

  const handleInput = (e) => {
    const inputText = e.target.value;
    setText(inputText);

    // Split the text into words
    let words = inputText.split(" ").filter((word) => word !== "");
    setWordCount(words.length);

    // Set character count
    setCharCount(inputText.length);
  };

  return (
    <>
    <Header/>
      <div className="box">
        <h1>Licznik Znaków</h1>
        <textarea
          name="textCount"
          id="textCount"
          cols="30"
          rows="8"
          value={text}
          onChange={handleInput}
        ></textarea>
        <p style={{ textAlign: "center" }}>
          Masz <span id="wordCount">{wordCount} słów</span> i{" "}
          <span id="charCount">{charCount} znaków</span>
        </p>
        <p>
          <strong>Jak używać:</strong>
        </p>
        <p>
          Wpisz lub wklej tekst w polu tekstowym, aby policzyć znaki i słowa.
        </p>
      </div>
      <div className="content">
        <h2>Dlaczego potrzebujesz licznika słów i znaków</h2>
        <p>
          Niezależnie od tego, czy jesteś studentem, pisarzem czy specjalistą
          ds. marketingu cyfrowego, śledzenie liczby słów i znaków jest
          niezbędne. Aby to ułatwić, stworzyliśmy bezpłatny internetowy licznik
          słów i liter, do którego można uzyskać dostęp z dowolnego komputera
          lub smartfona. Ale jak z niego korzystać i jak to robiono, zanim
          komputery stały się głównym nurtem? W tym przewodniku dowiesz się, jak
          działa nasze narzędzie i które zawody w dużym stopniu opierają się na
          liczeniu słów.
        </p>

        <h2>Jak ewoluowało liczenie słów</h2>
        <p>
          W przeszłości dziennikarze i pisarze ręcznie liczyli każde słowo i
          literę, aby spełnić rygorystyczne wytyczne redakcyjne. Jednak wraz z
          pojawieniem się technologii zadania tego typu zostały uproszczone.
          Nasz internetowy licznik słów automatyzuje ten proces, natychmiast
          podając całkowitą liczbę słów, liter i zdań oraz to, które znaki
          pojawiają się najczęściej w Twoim tekście.
        </p>

        <h2>Jak to działa?</h2>
        <p>
          Nie musisz znać się na technologii, aby korzystać z naszych liczników
          słów. Wklej lub wpisz tekst w polu wprowadzania i kliknij „policz”.
          Natychmiast otrzymasz zestawienie tekstu, w tym liczbę słów, liczbę
          znaków i listę liter w kolejności alfabetycznej. Nie jest wymagane
          żadne skomplikowane oprogramowanie ani umiejętności techniczne!
        </p>

        <h2>Kto czerpie korzyści z używania licznika słów?</h2>

        <p>
          Każdy, kto pisze, może skorzystać z licznika słów. Niezależnie od
          tego, czy jesteś dziennikarzem, studentem, marketerem czy specjalistą
          SEO, nasze narzędzie pomoże Ci upewnić się, że Twój tekst spełnia
          wymagania dotyczące długości. Dziennikarze muszą mieścić swoje
          artykuły w wąskich granicach słów, a specjaliści SEO muszą
          przestrzegać wytycznych dotyczących optymalnej długości treści
          internetowych. Nasze narzędzie zostało zaprojektowane tak, aby było
          intuicyjne, dzięki czemu każdy, niezależnie od wieku i doświadczenia,
          może z łatwością z niego korzystać.
        </p>

        <h2>Zawody polegające na liczeniu słów </h2>
        <p>
          Liczenie słów i liter ma kluczowe znaczenie w wielu branżach. Oto
          kilka zawodów, które regularnie korzystają z liczników słów: Pisarze
          <br />
          Dziennikarze
          <br />
          Marketerzy
          <br />
          Specjaliści SEO
          <br />
          Programiści
          <br />
          Projektanci
          <br />
          Sprzedawcy
          <br />
          Studenci
          <br />
          Nawet jeśli nie znajdujesz się w tych polach, prawdopodobnie w pewnym
          momencie policzysz słowa lub znaki.
        </p>

        <h2>
          Liczenie słów w wiadomościach e-mail, CV i mediach społecznościowych
        </h2>
        <p>
          W dzisiejszej erze cyfrowej platformy takie jak Twitter, LinkedIn i
          Instagram mają ograniczenia dotyczące znaków, których użytkownicy
          muszą przestrzegać. Nasze narzędzie pomoże Ci dostosować wiadomości do
          tych ograniczeń. Na przykład Twitter ma limit 280 znaków na tweet,
          podczas gdy LinkedIn dopuszcza 2000 znaków na post. Niezależnie od
          tego, czy piszesz post w mediach społecznościowych, piszesz e-mail,
          czy tworzysz CV, nasz licznik gwarantuje, że Twój tekst zmieści się w
          wymaganych granicach.
        </p>
      </div>
      <Footer/>
    </>
  );
};

export default WordCounter;
