import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Contact = () => {
  return (
    <>
    <Header/>
    <div className="contact_content">
      <h1>Skontaktuj się z nami</h1>
      <p>Jeśli masz jakiekolwiek pytania, skontaktuj się z nami.</p>
      <span>Wyślij e-mail na adres: @gmail.com</span>
    </div>
    <Footer/>
    </>
  )
}

export default Contact